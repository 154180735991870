/*
 * Container style
 */
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

/*
 * Scrollbar rail styles
 */
.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  height: 15px;
  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 0px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  width: 15px;
  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0;
  /* please don't change 'position' */
  position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: #eee;
  opacity: 0.9;
}

/*
 * Scrollbar thumb styles
 */
.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, height .2s ease-in-out;
  -webkit-transition: background-color .2s linear, height .2s ease-in-out;
  height: 6px;
  /* there must be 'bottom' for ps__thumb-x */
  bottom: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, width .2s ease-in-out;
  -webkit-transition: background-color .2s linear, width .2s ease-in-out;
  width: 6px;
  /* there must be 'right' for ps__thumb-y */
  right: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #999;
  height: 11px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #999;
  width: 11px;
}

/* MS supports */
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}

.pvtUi {
    color: #2a3f5f;
    font-family: Verdana;
    border-collapse: collapse;
}
.pvtUi select {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
}

.pvtUi td.pvtOutput {
    vertical-align: top;
}

table.pvtTable {
    font-size: 8pt;
    text-align: left;
    border-collapse: collapse;
    margin-top: 3px;
    margin-left: 3px;
    font-family: Verdana;
}
table.pvtTable thead tr th,
table.pvtTable tbody tr th {
    background-color: #ebf0f8;
    border: 1px solid #c8d4e3;
    font-size: 8pt;
    padding: 5px;
}

table.pvtTable .pvtColLabel {
    text-align: center;
}
table.pvtTable .pvtTotalLabel {
    text-align: right;
}

table.pvtTable tbody tr td {
    color: #2a3f5f;
    padding: 5px;
    background-color: #fff;
    border: 1px solid #c8d4e3;
    vertical-align: top;
    text-align: right;
}

.pvtTotal,
.pvtGrandTotal {
    font-weight: bold;
}

.pvtRowOrder,
.pvtColOrder {
    cursor: pointer;
    width: 15px;
    margin-left: 5px;
    display: inline-block;
    user-select: none;
    text-decoration: none !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
}

.pvtAxisContainer,
.pvtVals {
    border: 1px solid #a2b1c6;
    background: #f2f5fa;
    padding: 5px;
    min-width: 20px;
    min-height: 20px;
}

.pvtRenderers {
    padding-left: 5px;
    -webkit-user-select: none;
            user-select: none;
}

.pvtDropdown {
    display: inline-block;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
    margin: 3px;
}

.pvtDropdownIcon {
    float: right;
    color: #a2b1c6;
}
.pvtDropdownCurrent {
    text-align: left;
    border: 1px solid #a2b1c6;
    border-radius: 4px;
    display: inline-block;
    position: relative;
    width: 210px;
    box-sizing: border-box;
    background: white;
}

.pvtDropdownCurrentOpen {
    border-radius: 4px 4px 0 0;
}

.pvtDropdownMenu {
    background: white;
    position: absolute;
    width: 100%;
    margin-top: -1px;
    border-radius: 0 0 4px 4px;
    border: 1px solid #a2b1c6;
    border-top: 1px solid #dfe8f3;
    box-sizing: border-box;
}

.pvtDropdownValue {
    padding: 2px 5px;
    font-size: 12px;
    text-align: left;
}
.pvtDropdownActiveValue {
    background: #ebf0f8;
}

.pvtVals {
    text-align: center;
    white-space: nowrap;
    vertical-align: top;
    padding-bottom: 12px;
}

.pvtRows {
    height: 35px;
}

.pvtAxisContainer li {
    padding: 8px 6px;
    list-style-type: none;
    cursor: move;
}
.pvtAxisContainer li.pvtPlaceholder {
    -webkit-border-radius: 5px;
    padding: 3px 15px;
    border-radius: 5px;
    border: 1px dashed #a2b1c6;
}
.pvtAxisContainer li.pvtPlaceholder span.pvtAttr {
    display: none;
}

.pvtAxisContainer li span.pvtAttr {
    -webkit-text-size-adjust: 100%;
    background: #f3f6fa;
    border: 1px solid #c8d4e3;
    padding: 2px 5px;
    white-space: nowrap;
    border-radius: 5px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
}

.pvtTriangle {
    cursor: pointer;
    color: #506784;
}

.pvtHorizList li {
    display: inline-block;
}
.pvtVertList {
    vertical-align: top;
}

.pvtFilteredAttribute {
    font-style: italic;
}

.sortable-chosen .pvtFilterBox {
    display: none !important;
}

.pvtCloseX {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 18px;
    cursor: pointer;
    text-decoration: none !important;
}

.pvtDragHandle {
    position: absolute;
    left: 5px;
    top: 5px;
    font-size: 18px;
    cursor: move;
    color: #a2b1c6;
}

.pvtButton {
    color: #506784;
    border-radius: 5px;
    padding: 3px 6px;
    background: #f2f5fa;
    border: 1px solid;
    border-color: #c8d4e3;
    font-size: 14px;
    margin: 3px;
    transition: 0.34s all cubic-bezier(0.19, 1, 0.22, 1);
    text-decoration: none !important;
}

.pvtButton:hover {
    background: #e2e8f0;
    border-color: #a2b1c6;
}

.pvtButton:active {
    background: #d1dae6;
}

.pvtFilterBox input {
    border: 1px solid #c8d4e3;
    border-radius: 5px;
    color: #506784;
    padding: 0 3px;
    font-size: 14px;
}

.pvtFilterBox input:focus {
    border-color: #119dff;
    outline: none;
}

.pvtFilterBox {
    z-index: 100;
    width: 300px;
    border: 1px solid #506784;
    background-color: #fff;
    position: absolute;
    text-align: center;
    user-select: none;
    min-height: 100px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
}

.pvtFilterBox h4 {
    margin: 15px;
}
.pvtFilterBox p {
    margin: 10px auto;
}
.pvtFilterBox button {
    color: #2a3f5f;
}
.pvtFilterBox input[type='text'] {
    width: 230px;
    color: #2a3f5f;
    margin-bottom: 5px;
}

.pvtCheckContainer {
    text-align: left;
    font-size: 14px;
    white-space: nowrap;
    overflow-y: scroll;
    width: 100%;
    max-height: 30vh;
    border-top: 1px solid #dfe8f3;
}

.pvtCheckContainer p {
    margin: 0;
    margin-bottom: 1px;
    padding: 3px;
    cursor: default;
}

.pvtCheckContainer p.selected {
    background: #ebf0f8;
}

.pvtOnly {
    display: none;
    width: 35px;
    float: left;
    font-size: 12px;
    padding-left: 5px;
    cursor: pointer;
}

.pvtOnlySpacer {
    display: block;
    width: 35px;
    float: left;
}

.pvtCheckContainer p:hover .pvtOnly {
    display: block;
}
.pvtCheckContainer p:hover .pvtOnlySpacer {
    display: none;
}

.pvtRendererArea {
    padding: 5px;
}

/* muli-200normal - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src:
    local('Muli Extra Light '),
    local('Muli-Extra Light'),
    url(/static/media/muli-latin-200.1dfc66c7.woff2) format('woff2'), 
    url(/static/media/muli-latin-200.b79246db.woff) format('woff'); /* Modern Browsers */
}

/* muli-200italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src:
    local('Muli Extra Light italic'),
    local('Muli-Extra Lightitalic'),
    url(/static/media/muli-latin-200italic.ed5f4d70.woff2) format('woff2'), 
    url(/static/media/muli-latin-200italic.787c91cf.woff) format('woff'); /* Modern Browsers */
}

/* muli-300normal - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Muli Light '),
    local('Muli-Light'),
    url(/static/media/muli-latin-300.73d23299.woff2) format('woff2'), 
    url(/static/media/muli-latin-300.6e03d5eb.woff) format('woff'); /* Modern Browsers */
}

/* muli-300italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Muli Light italic'),
    local('Muli-Lightitalic'),
    url(/static/media/muli-latin-300italic.49ef162a.woff2) format('woff2'), 
    url(/static/media/muli-latin-300italic.25532f4b.woff) format('woff'); /* Modern Browsers */
}

/* muli-400normal - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Muli Regular '),
    local('Muli-Regular'),
    url(/static/media/muli-latin-400.8160dac0.woff2) format('woff2'), 
    url(/static/media/muli-latin-400.b0f17b38.woff) format('woff'); /* Modern Browsers */
}

/* muli-400italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Muli Regular italic'),
    local('Muli-Regularitalic'),
    url(/static/media/muli-latin-400italic.730c6bfa.woff2) format('woff2'), 
    url(/static/media/muli-latin-400italic.688fb50a.woff) format('woff'); /* Modern Browsers */
}

/* muli-600normal - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Muli SemiBold '),
    local('Muli-SemiBold'),
    url(/static/media/muli-latin-600.4273185a.woff2) format('woff2'), 
    url(/static/media/muli-latin-600.0f2fbd56.woff) format('woff'); /* Modern Browsers */
}

/* muli-600italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    local('Muli SemiBold italic'),
    local('Muli-SemiBolditalic'),
    url(/static/media/muli-latin-600italic.7567e136.woff2) format('woff2'), 
    url(/static/media/muli-latin-600italic.9897cb2b.woff) format('woff'); /* Modern Browsers */
}

/* muli-700normal - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Muli Bold '),
    local('Muli-Bold'),
    url(/static/media/muli-latin-700.d3703a0d.woff2) format('woff2'), 
    url(/static/media/muli-latin-700.a6600f60.woff) format('woff'); /* Modern Browsers */
}

/* muli-700italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Muli Bold italic'),
    local('Muli-Bolditalic'),
    url(/static/media/muli-latin-700italic.af763a17.woff2) format('woff2'), 
    url(/static/media/muli-latin-700italic.00211c4a.woff) format('woff'); /* Modern Browsers */
}

/* muli-800normal - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src:
    local('Muli ExtraBold '),
    local('Muli-ExtraBold'),
    url(/static/media/muli-latin-800.ab72c9c1.woff2) format('woff2'), 
    url(/static/media/muli-latin-800.47fd62e6.woff) format('woff'); /* Modern Browsers */
}

/* muli-800italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src:
    local('Muli ExtraBold italic'),
    local('Muli-ExtraBolditalic'),
    url(/static/media/muli-latin-800italic.33637beb.woff2) format('woff2'), 
    url(/static/media/muli-latin-800italic.a1e64515.woff) format('woff'); /* Modern Browsers */
}

/* muli-900normal - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Muli Black '),
    local('Muli-Black'),
    url(/static/media/muli-latin-900.37700966.woff2) format('woff2'), 
    url(/static/media/muli-latin-900.66c9d5ed.woff) format('woff'); /* Modern Browsers */
}

/* muli-900italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Muli Black italic'),
    local('Muli-Blackitalic'),
    url(/static/media/muli-latin-900italic.f99056b6.woff2) format('woff2'), 
    url(/static/media/muli-latin-900italic.3b4a9a0e.woff) format('woff'); /* Modern Browsers */
}


